<template>
  <div>
    <div v-if="loadPage">
      <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#"
            ><img
              src="@/assets/images/logo.png"
              width="80"
              height="80"
              class="d-inline-block align-top"
              alt=""
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul
              class="navbar-nav mr-auto sidenav text-left px-3"
              id="navAccordion"
            >
              <li class="nav-item mt-5">
                <router-link to="/warehouse/dashboard" class="nav-link"
                  >Dashboard</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/warehouse/customer-packages" class="nav-link"
                  >Customer Packages</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/warehouse/notification" class="nav-link"
                  >Notification</router-link
                >
              </li>
              <!-- <li class="nav-item">
            <a
              class="nav-link nav-link-collapse"
              href="#"
              id="hasSubItems"
              data-toggle="collapse"
              data-target="#collapseSubItems2"
              aria-controls="collapseSubItems2"
              aria-expanded="false"
              >Item 2</a
            >
            <ul
              class="nav-second-level collapse"
              id="collapseSubItems2"
              data-parent="#navAccordion"
            >
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span class="nav-link-text">Item 2.1</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span class="nav-link-text">Item 2.2</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Item 3</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nav-link-collapse"
              href="#"
              id="hasSubItems"
              data-toggle="collapse"
              data-target="#collapseSubItems4"
              aria-controls="collapseSubItems4"
              aria-expanded="false"
              >Item 4</a
            >
            <ul
              class="nav-second-level collapse"
              id="collapseSubItems4"
              data-parent="#navAccordion"
            >
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span class="nav-link-text">Item 4.1</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span class="nav-link-text">Item 4.2</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span class="nav-link-text">Item 4.2</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Item 5</a>
          </li> -->
            </ul>
            <form class="form-inline ml-auto mt-2 mt-md-0">
              <button
                type="button"
                v-if="isLoggedIn"
                class="btn btn-primary my-2 my-sm-0 px-5"
                @click.prevent="logout"
              >
                Logout
              </button>
            </form>
          </div>
        </div>
      </nav>

      <main class="content-wrapper position-relative gap-100">
        <div class="container-fluid">
          <div class="card shadow ml-4 h-100">
            <div class="card-body">
              <router-view />
            </div>
          </div>
        </div>
      </main>

      <!-- <footer class="footer">
      <div class="container">
        <div class="text-center">
          <span>Coded by <a href="https://si-dev.com/ru">SI-Dev</a>, 2018</span>
        </div>
      </div>
    </footer> -->
    </div>
  </div>
</template>

<style scoped>
/* .navbar {
  padding: 0.5rem 1rem !important;
} */
body {
  padding-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
  background-color: #45aaf2;
  border-radius: 10px;
  padding-left: 10px;
  margin-left: 5px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  line-height: 3.5rem;
  background-color: #ccc;
}

.nav-link:hover {
  transition: all 0.4s;
}

.nav-link-collapse:after {
  float: right;
  content: "\f067";
  font-family: "FontAwesome";
}

.nav-link-show:after {
  float: right;
  content: "\f068";
  font-family: "FontAwesome";
}

.nav-item ul.nav-second-level {
  padding-left: 0;
}

.nav-item ul.nav-second-level > .nav-item {
  padding-left: 20px;
}

@media (min-width: 992px) {
  .sidenav {
    position: absolute;
    top: 50px;
    left: 30px;
    width: 230px;
    height: calc(80vh - 1.5rem);
    margin-top: 3.5rem;
    background: #343a40;
    box-sizing: border-box;
    border-radius: 15px;
    /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
  }

  .navbar-expand-lg .sidenav {
    flex-direction: column;
  }

  .content-wrapper {
    margin-left: 230px;
  }

  .footer {
    width: 100%;
  }
}
</style>
<script>
import Vue from "vue";
export default {
  data() {
    return {
      loadPage: true,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    this.cekLogin();
  },

  mounted() {
    this.cekLogin();
  },
  methods: {
    cekLogin() {
      if (localStorage.getItem("role") !== "warehouse") {
        this.loadPage = false;
        Vue.swal(
          "Unauthorized!",
          "You do not have rights to access this page!",
          "error"
        ).then(() => {
          if (localStorage.getItem("role") == "admin") {
            this.$router.push("/admin/dashboard");
          } else if (localStorage.getItem("role") == "customers") {
            this.$router.push("/customers/my-profile");
          }
        });
      }
    },
    logout: function () {
      Vue.swal({
        title: "Are you sure?",
        text: "Leaving this page, you will return to the main page of the website",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Logout!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("logout").then(() => {
            Vue.swal("Logout Successfully!", "See you again!", "success").then(
              () => {
                this.$router.push("/login");
              }
            );
          });
        }
      });
    },
  },
};
</script>
